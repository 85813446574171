<template>
  <div>
    <h3>Field Assistant Requests</h3>
    <div class="dashboard-wraper mt-3" v-if="! loading">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Category</th>
            <th>Region</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="requests.length == 0">
            <td colspan="6">There is no data to display.</td>
          </tr>
          <tr v-for="(req, i) in requests" :key="`request-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ req.name }}</td>
            <td>{{ req.category }}</td>
            <td>{{ req.region }}</td>
            <td>{{ req.status }}</td>
            <td>
              <router-link :to="{ name: 'my-account.field-assistants.show', params: { id: req.id }}">Details </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <loading v-if="loading" />
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      requests: [],
      loading: true
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/my-account/field-assistants`).then(response => {
        this.requests = response.data.requests
        this.loading = false
      })
    }
  }
}
</script>